import React from 'react'
import PropTypes from 'prop-types'

import {withNamespaces} from 'react-i18next'

import Link from 'components/common/Link'
import Tree, {TreeNode} from 'components/common/Tree'

import {colors} from 'utils/styles'

import arrow from './arrow.svg'
import classes from './DocsCategoryTree.module.scss'

export class DocsCategoryTree extends React.Component {
  static propTypes = {
    product: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  }

  loop = (expandedKey, {articles = [], categories = []}) => {
    const nodes = []
    const {product} = this.props

    categories.map(category => {
      const style = expandedKey === category.path ? {color: colors.brand, fontWeight: 'bold'} : {}

      nodes.push(
        <TreeNode
          key={category.path}
          title={<Link style={style} to={`/${product}/docs/${category.path}`}>{category.title}</Link>}
        >
          {this.loop(expandedKey, category)}
        </TreeNode>
      )
    })

    articles.map(({path, title}) => {
      const style = expandedKey === path ? {color: colors.brand, fontWeight: 'bold'} : {}

      nodes.push(
        <TreeNode key={path} title={<Link style={style} to={`/${product}/docs/${path}`}>{title}</Link>} isLeaf />
      )
    })

    return nodes
  }

  switcherIcon = ({isLeaf, expanded}) => {
    if (isLeaf) {
      return null
    }

    return (
      <img
        src={arrow}
        style={{
          cursor: 'pointer',
          marginTop: '10',
          transform: `rotate(${expanded ? 90 : 0}deg)`,
          backgroundColor: '#fbfafc'
        }}
      />
    )
  }

  render () {
    const {content, location} = this.props

    const expandedKey = location.pathname.split('/').slice(4).join('/')

    return (
      <Tree
        showLine
        showIcon={false}
        defaultExpandedKeys={[expandedKey]}
        className={classes.tree}
        switcherIcon={this.switcherIcon}
      >
        {this.loop(expandedKey, content)}
      </Tree>
    )
  }
}

export const I18NCategoryTree = ({lng, product, content, location}) => (
  <DocsCategoryTree product={product} content={content[lng]} location={location} />
)

I18NCategoryTree.propTypes = {
  lng: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default withNamespaces()(I18NCategoryTree)
