import React from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import Markdown from 'react-markdown'
import {withNamespaces} from 'react-i18next'

import Layout from 'components/Layout'
import CategoryTree from 'components/fleerp/Docs/CategoryTree'

const Docs = ({t, navigate, location, pageContext}) => {
  const sidebar = (
    <div style={{height: '100%', overflow: 'auto'}}>
      <CategoryTree t={t} location={location} />
    </div>
  )

  return (
    <Layout fluid sidebar={sidebar} navigate={navigate} location={location}>
      <Helmet
        title={t('fleerp.DocsPage.meta.title')}
        meta={[
          {
            name: 'description',
            content: t('fleerp.DocsPage.meta.description')
          },
          {
            name: 'keywords',
            content: t('fleerp.DocsPage.meta.keywords')
          }
        ]}
      />
      <Markdown escapeHtml={false} source={pageContext.html} />
    </Layout>
  )
}

Docs.propTypes = {
  t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default withNamespaces()(Docs)
